import {
  commonInputs,
  saveButton,
} from './form';

import {
  localSelectionsCreationForm
} from './product'

import customer from './customer'
import event from './event'

import signinOrRegister from './signinOrRegister'

export default {

  title: "Användare",
  description: "Administration av användar- och kunduppgifter",

  inputs: {
    ...commonInputs
  },

  submit: saveButton,

  signinOrRegister,

  customerHeader: {
    accessLevel: "",
    accessLevel_own: "Tillgång till egen data",
    accessLevel_admin: "Administratör",
  },

  orders: {
    header: "Orderhistorik",
    table: {
      orderDate: {
        column: "Datum"
      },
      amount: {
        column: "Belopp",
        value: "{{ total }}, varav moms {{ tax }}"
      },
      madeByUser: {
        column: "Skapad av",
      },
    },
    empty: {
      description: "Inga ordrar ... än"
    }
  },

  images: {
    header: "Bilder",
    table: {
      file: {
        column: "Fil"
      },
      name: {
        column: "Namn"
      },
      remove: {
        column: ""
      }
    },
    creationForm: localSelectionsCreationForm
  },

  customerAddressList: {
    header: "Adresser",
    name: {
      label: "Namn"
    },
    address: {
      label: "Adress"
    },
    contact: {
      label: "Kontaktuppgifter"
    }
  },

  resetPassword: {
    title: "Hej {{name}} ({{email}})",
    description: "Vänligen välj ett bra lösenord",
  },

  newPassword: {
    password: "Lösenord",
    retypePassword: "Repetera lösenord",
    button: {
      label: "Spara"
    }
  },

  selectCustomer: {
    button: {
      label: "Byt kund"
    }
  },

  changePassword: {
    button: {
      label: "Ändra lösenord"
    }
  },

  signOut: {
    button: {
      label: "Logga ut"
    }
  },

  removeUserCustomerAccess: {
    confirm: {
      message: "Vill du ta bort {{ customerName }} från din kundlista?"
    }
  },

  userNav: {
    root: {
      label: "Överblick",
    },
    customerOrders: {
      label: "Beställningar",
    },
    customerEvents: {
      label: "Evenemang"
    },
    customerImages: {
      label: "Bilder"
    },
    addresses: {
      label: "Adressbok",
    },
    account: {
      label: "Användarkonto"
    },
    staff: {
      label: "Personal"
    },
    contact: {
      label: "Kontakta oss!",
    },
  },

  contact: {
    message: {
      label: "Meddelande",
      placeholder: "Ställ en fråga, eller säg bara Hej!"
    }
  },
  getStarted: {
    inputs: {
      eventName: "Namn",
      eventDate: "Datum (användningsdatum)"
    },
    event: {
      details: "Evenemang",
    },

    customerTypes: {
      individual: "Privatperson",
      company: "Företag",
      union: "Förening",
    }
  },

  // customers: {
  //   header: {
  //     pre: "{{ userName }}",
  //     heading: "Val av kund",
  //     post: "Utöver att handla till dig själv, så kan du representera din förening eller ditt företag."
  //   },
  //   list: {
  //     header: "Dina kunder",
  //     item: {
  //       type: "<fel>",
  //       type_individual: "Privatperson",
  //       type_company: "Företag",
  //       type_union: "Förening",
  //     }
  //   }
  // },

  dashboard: {
    header: {
      heading: "Välkommen till dina sidor",
      post: "Här kan du göra både det ena och det andra."
    },
    moreToCome: "Vi jobbar hela tiden med att utveckla vår hemsida och just nu är den här delen under konstruktion. Dessa sidor kommer regelbundet att fyllas på med mer information relevant för just dig."
  },

  staff: {
    header: {
      pre: "",
      heading: "Endast för personal",
      post: "Här kommer det finnas fin-fina funktioner så småningom :)"
    },

    currentCustomer: "Nuvarande kund: {{ customerName }}",
    selectCustomer: {
      header: {
        pre: "",
        heading: "Välj kund",
        post: ""
      },
      button: {
        label: "Välj kund",
        label_change: "Byt kund"
      },
      search: {
        placeholder: "Sök efter id eller namn",
      }
    },
    resetCustomer: {
      button: {
        label: "Nollställ kundval"
      }
    }
  },

  account: {

    header: "Användarkonto",

    user: {
      signedInAs: {
        header: "Användare",
        description: "Inloggad som {{ userName }}"
      },
      contactInfo: {
        header: "Kontaktinformation",
        address: "Address",
        mobile: "Mobil"
      },

      editContactInfo: {
        button: {
          label: "Ändra kontaktinformation"
        },
        submit: {
          label: "Spara ändringar"
        },
        inputs: {
          street: "Adress",
          zip: "Postnummer",
          city: "Ort",
          mobile: "Mobil",
        }
      },

      newPassword: {
        header: {
          pre: "",
          heading: "Nytt lösenord",
          post: "Ett lösenord måste vara minst 4 tecken långt"
        },
        inputs: {
          password: "Lösenord",
          retypePassword: "Repetera lösenord"
        },
        submit: {
          label: "Spara"
        }
      }
    },
    customer: {
      header: "Kund",
      description: "Vald kund: {{ customerName }}",
      description_admin: "Vald kund: {{ customerName }} (admin-rättigheter)",
      noCustomerSelectedDescription: "Ingen vald kund",

      select: {
        header: {
          pre: "",
          heading: "Val av kund",
          post: "Utöver att handla till dig själv, så kan du representera din förening eller ditt företag."
        },
        list: {
          header: "Dina kunder",
          item: {
            type: "<fel>",
            type_individual: "Privatperson",
            type_company: "Företag",
            type_union: "Förening",
          },
          submit: {
            label: "Spara kundval"
          },
          addButton: {
            label: "Lägg till kund"
          }
        },

        typeSelection: {
          header: {
            pre: "",
            heading: "Välj kundtyp",
            post: "Vilken typ av kund ska ni handla för?"
          }
        },

        union: {
          search: {
            header: {
              pre: "",
              heading: "Föreningsregistret",
              post: "Vi har många föreningar i vårt register. Sök efter din!"
            },
            create: "Skapa ny förening '{{ value }}'"
          },
          create: {
            header: {
              pre: "",
              heading: "Skapa förening",
              post: ""
            },
            inputs: {
              name: "Namn på förening",
              activities: "Aktiviteter",
            },
            submit: {
              label: "Spara"
            }
          },
        },

        company: {
          create: {
            header: {
              pre: "",
              heading: "Skapa företag",
              post: ""
            },
            inputs: {
              name: "Företagsnamn",
              registrationNumber: "Organisationsnummer",
            },
            submit: {
              label: "Spara"
            }
          },
        }
      },
      end: {
        header: {
          pre: "",
          heading: "{{ customerName }}",
          post: "Nu är du redo att handla åt {{ customerName }}"
        },
        closeButton: {
          label: "Ok"
        },
        customerOrdersPage: {
          label: "Orderhistorik"
        },
        applyForCustomerAdminButton: {
          label: "Ansök om admin-rättigheter"
        }
      }
    }
  },
  applyForCustomerAdmin: {
    button: {
      label: "Ansök om administratörsrättigheter"
    },
    pendingAccess: {
      message: "Ansökan om administratörsrättigheter är skickad."
    }
  },

  newPasswordModal: {
    header: "Ändra lösenord"
  },

  customer: {
    event,
  },
  orderSuggestion: customer.events.wizard.orderSuggestion
}