import find from 'lodash/find';

const _bySlug = (categories, slug) => find(categories, c => c.slug === slug)
const _byId = (categories, id) => find(categories, c => c.id === id)

export const getProductCategoriesFromState = (state) => state.productCategories;

export const getProductCategory = (state, byFunc, ...args) => {

  const _getRecursive = (categories = []) => {
    const foundCategory = byFunc(categories, ...args);

    if(foundCategory) {
      return foundCategory;
    }

    for(let i=0; i<categories.length; i++) {
      const category = categories[i];
      const foundChildCategory = _getRecursive(category.children);
      if(foundChildCategory) {
        return foundChildCategory;
      }
    }

    return null;
  }

  return _getRecursive(getProductCategoriesFromState(state));
}

export const getProductCategoryById = (state, id) => getProductCategory(state, _byId, id)
export const getProductCategoryBySlug = (state, slug) => getProductCategory(state, _bySlug, slug)

export const getProductCategoryFlatTree = (state, id) => {

  if(id === false) {
    return [];
  }

  const _getRecursive = (categories, parents = []) => {
    const foundCategory = find(categories, c => c.id === id);

    if(foundCategory) {
      return [...parents, foundCategory];
    }

    for(let i=0; i<categories.length; i++) {
      const category = categories[i];
      const foundChildCategories = _getRecursive(category.children || [], [...parents, category]);
      
      if(foundChildCategories) {
        return foundChildCategories;
      }
    }

    return null;
  }

  return _getRecursive(getProductCategoriesFromState(state));
}

export const getProductCategoryChildren = (state, parentId = null) => {

  if (parentId === null) {
    return state.productCategories;
  }

  const parentCategory = getProductCategory(state, _byId, parentId);
  const childCategories = parentCategory ? (parentCategory.children || []) : [];
  return childCategories;
}

const _onlyFeaturedCategories = (categories) => categories.filter(c => c.featuredProductIds.length > 0);

export const getFeaturedProductCategoryChildren = (state, parentId = null) => {
  return _onlyFeaturedCategories(getProductCategoryChildren(state, parentId));
}

export const getFeaturedRootProductCategories = (state) => _onlyFeaturedCategories(getProductCategoriesFromState(state));