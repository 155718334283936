export default {
  item: {

    attribute: {
      description: "{{value}}",
      description_plural: "{{count}} alternativ",
      description_size_plural: "{{count}} storlekar",
      description_color_plural: "{{count}} färger",
      description_value_plural: "{{count}} valörer",

      description_extended: "{{value}}",
      description_extended_plural: "{{count}} alternativ",
      description_extended_size_plural: "{{count}} storlekar ({{value}})",
      description_extended_color_plural: "{{value}}",
      description_extended_value_plural: "{{value}}",

    },

    price: "Från {{value}}",

    states: {
      isNews: "Nyhet",
      onSale: "Utförsäljning"
    }
  },

  filters: {
    activity: {
        placeholder: "Välj sport",
        label: "Sport"
    },
    productCategory: {
      placeholder: "Välj kategori",
    }
  },

  ordering: {
    label: "Sortera efter",
    option: {
      label_name: "Namn",
      label_price: "Pris",
      label_articleNumber: "Artikelnummer"
    }
  },

  buttons: {
    nextPage: {
      idle: {
        label: "Visa fler"
      },
      fetching: {
        label: "Laddar .."
      }
    }
  },

  noProductWithCurrentFilters: "Hittade inga produkter med valda filter :("
}