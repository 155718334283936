import React from 'react';
import PropTypes from 'prop-types'

import ReactModal from 'react-modal';

import { removeModal } from '../../actions';
import { arrayToClassName } from '../../utils/elements'

import './Modal.scss';

ReactModal.setAppElement('#root');

const defaultModalContentStyle = {
}

const
  CENTER = 'CENTER',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'

export const VERTICAL_MODAL_POSITIONS = {
  CENTER,
  TOP,
  BOTTOM
}

const
  VISIBLE = 'VISIBLE',
  AUTO = 'AUTO',
  SCROLL = 'SCROLL'

export const OVERFLOWS = {
  VISIBLE,
  AUTO,
  SCROLL
}

class BaseModal extends React.Component {
  static propTypes = {
    confirmCloseMessage: PropTypes.string
  }

  static defaultProps = {
    overflow: AUTO,
    verticalPosition: CENTER,
    confirmCloseMessage: null
  }

  handleRequestClose = () => {
    const close = (
      !this.props.confirmCloseMessage || window.confirm(this.props.confirmCloseMessage)
    )

    if (close) {
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
  }

  render() {
    const { children, isOpen, overflow, verticalPosition } = this.props

    return (
      <ReactModal
        overlayClassName={arrayToClassName([
          verticalPosition === CENTER && 'v-center',
          verticalPosition === TOP && 'v-top',
          verticalPosition === BOTTOM && 'v-bottom',
        ])}
        className={arrayToClassName([
          "random-class-to-override-default-inline-style"
        ])}
        isOpen={isOpen}
        contentLabel="modal"
        onRequestClose={this.handleRequestClose}
        style={{
          content: {
            ...defaultModalContentStyle,
            ...({
              [AUTO]: {
                overflow: 'auto',
              },
              [VISIBLE]: {
                overflow: 'visible',
              },
              [SCROLL]: {
                overflow: 'scroll',
              },
            }[overflow])
          }
        }}
      >
        {children}
      </ReactModal>
    );
  }
}

export const BareModal = ({ children, ...modalProps }) => (
  <BaseModal {...modalProps}>
    <div>
      {children}
    </div>
  </BaseModal>
)

export const ModalWithClose = ({ children, onClose, ...modalProps }) => (
  <BaseModal onClose={onClose} {...modalProps}>
    <button className="btn btn-outline-dark btn-sm pull-right" onClick={onClose}><i className="fa fa-close" /></button>
    <div>
      {children}
    </div>
  </BaseModal>
);

export default ModalWithClose;


export const
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  SIZES = {
    SMALL,
    MEDIUM,
    LARGE
  }

export function ModalBody({ size, children }) {
  return (
    <div style={{
      [SMALL]: {
        // width: '70vw',
        maxWidth: 400
      },
      [MEDIUM]: {
        // width: '70vw',
        maxWidth: 600
      },
      [LARGE]: {
        // width: '70vw',
        maxWidth: 800
      }
    }[size]}>
      {children}
    </div>
  )
}

ModalBody.propTypes = {
  size: PropTypes.oneOf([
    SMALL,
    MEDIUM,
    LARGE
  ]),
  children: PropTypes.element.isRequired
}

ModalBody.defaultProps = {
  size: MEDIUM
}

