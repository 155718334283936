import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import find from 'lodash/find';
import get from 'lodash/get';

import {
  editOrderRow,
  removeOrderRow,
  resetOrderRows,
} from 'actions';

import {
  attributeValueIdsToString
} from 'selectors/selectedProduct';

import {
  getFieldSelectionDisplay
} from 'selectors'

import { fetchProductArticles, fetchProduct } from 'api';
import { formatPrice } from 'utils/content';
import { confirmDialog } from 'utils/dialog';

import { waitUntilPromiseIsResolved } from 'containers/hoc';

import { Link } from 'react-router-dom';
import routes from 'routes';

import { BareModal } from './modals/Modal';
import ProductArticleForm from './Product/ProductArticleForm';
import { caseSelector } from 'utils/helpers';


const ProductArticleDisplayName = ({ article, articleAttributes }) => {
  return (
    <span>
      {article.product.name} {attributeValueIdsToString(article.attributeValueIds, articleAttributes)}
    </span>
  )
}

const transformIncomingValuesToState = (productArticleFormProps) => {
  const {
    quantity = productArticleFormProps.minimumQuantity,
    inputValues = [],
    optionSelectionPicks = [],
    productFieldInputs = [],
  } = productArticleFormProps.initData || {};

  const _getOptionScope = (id) => find(productArticleFormProps.optionScopes, os => os.id === id);
  const _getOption = (optionId) => find(productArticleFormProps.options, o => o.id === optionId);
  const _getSelection = (optionId, selectionId) => find(_getOption(optionId).selections, s => s.id === selectionId);

  const state = {
    inputValues: inputValues.reduce((accum, { inputId, value }) => ({
      [inputId]: value,
      ...accum
    }), {}),
    optionSelectionPicks: optionSelectionPicks.reduce((accum, { optionScopeId, selectionId, inputs }) => {
      const optionScope = _getOptionScope(optionScopeId);
      const selection = _getSelection(optionScope.optionId, selectionId);

      return {
        [optionScope.identifier]: {
          optionSelectionIdentifier: selection.identifier,
          inputValues: inputs.reduce((accum, { inputId, value }) => ({
            [inputId]: value,
            ...accum
          }), {})
        },
        ...accum
      }
    }, {}),
    productFieldInputs,
    quantity,
  }

  return state;
}

const OrderRowEditModalContent = waitUntilPromiseIsResolved(({ article }) => {
  return fetchProduct(article.product.id, article.id);

}, product => ({ product }))(translate('order')(({ orderRow, article, articleAttributes, product, onSubmit, onCancel, t }) => {
  const articleOptionScopes = product.optionScopes.filter(os => article.optionScopeIds.indexOf(os.id) !== -1);

  const images = [
    article.image,
    article.product.image,
    ...article.product.images,
  ].filter(i => i !== null);
  const [image] = images

  return (
    <div>
      <div className="d-flex align-items-center">
        {
          image &&
          <div style={{
            backgroundImage: `url(${image && image.processedUrls.default})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "50px",
            height: "50px",
          }}></div>
        }
        <h2 className="ml-3">
          <ProductArticleDisplayName article={article} articleAttributes={articleAttributes} />
        </h2>
      </div>

      <hr />

      <ProductArticleForm

        articleId={article.id}

        minimumQuantity={product.minimumOrderAmount}
        maximumQuantity={100000}

        price={article.price}
        inputs={(product.inputs || []).filter(x => article.inputIds.indexOf(x.id) !== -1)}
        optionScopes={articleOptionScopes}
        productFields={product.productFields}

        initData={{
          quantity: orderRow.quantity,
          inputValues: orderRow.inputValues,
          optionSelectionPicks: orderRow.optionSelectionPicks,
          productFieldInputs: orderRow.productFieldInputs
        }}

        onSubmit={onSubmit}

        transformIncomingValuesToState={transformIncomingValuesToState}
      >
        <button className="btn btn-outline-primary" type="submit">{t('rows.edit.save')}</button>
        <button className="btn btn-outline-dark ml-2" type="button" onClick={onCancel}>{t('rows.edit.cancel')}</button>
      </ProductArticleForm>
    </div>

  );
}));

class OrderRowEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleIsOpen(force = undefined) {
    this.setState({ isOpen: force !== undefined ? force : !this.state.isOpen });
  }

  onSubmit(...args) {
    this.props.onSubmit(...args);
    this.toggleIsOpen(false);
  }

  render() {

    const { onSubmit, ...modalContentProps } = this.props;

    return (
      <span>
        <button className="btn btn-sm btn-outline-primary" onClick={() => this.toggleIsOpen(true)}><i className="fa fa-pencil" /></button>

        <BareModal
          isOpen={this.state.isOpen}
          onClose={() => this.toggleIsOpen(false)}
        >
          <OrderRowEditModalContent
            {...modalContentProps}
            onSubmit={this.onSubmit}
            onCancel={() => this.toggleIsOpen(false)}
          />
        </BareModal>
      </span>
    );
  }
}

function OrderRowDetailLabel({ value }) {
  return (
    <span>{value}: </span>
  )
}

function OrderRowDetailValue({ value, meta }) {
  return (
    <span>
      {value} {
        meta && (
          <span>({meta})</span>
        )
      }
    </span>
  )
}

const ProductFieldLabel = connect((state, { productFieldId }) => {
  const productField = find(state.productFields, x => x.id === productFieldId);

  return {
    value: productField && get(productField, 'label', get(productField, 'name', '<loading>'))
  }
})(OrderRowDetailLabel);

const ProductFieldValue = connect(
  (state, { productFieldId, input }) => {
    const productField = find(state.productFields, x => x.id === productFieldId);

    if (productField === undefined) {
      return {
        value: "loading"
      };
    }

    function _getValue(_input, type) {
      return caseSelector({
        selection: () => {

          return getFieldSelectionDisplay(state, _input)
          // const selection = find(state.fieldSelections, x => x.id === _input.selectionId);
          // return selection && `${selection.identifier ? `${selection.identifier} - ` : ''}${selection.name}` ||  '<ERROR>'
        }
      }, () => _input.value)(type)()
    }

    return {
      value: _getValue(input, productField.type),
      meta: (input.settingInputs && input.settingInputs.length > 0) ? (
        input.settingInputs.map(settingInput => {
          const setting = find(productField.settings, s => settingInput.settingId === s.id);
          const value = _getValue(settingInput, setting.type);
          return `${setting.label || setting.name}=${value}`;
        }).join(", ")
      ) : undefined,
    }
  }
)(OrderRowDetailValue);

const OrderRow = connect(null, {
  editOrderRow
})(translate('order')(({ orderRow, productArticle, productArticleAttributes, removeRow, editOrderRow, useExtended, isEditable, t }) => {

  const {
    id,
    inputValues = [],
    optionSelectionPicks = [],
    productFieldInputs = [],
    quantity
  } = orderRow;

  const images = [
    productArticle.image,
    productArticle.product.image,
    ...productArticle.product.images,
  ].filter(i => i !== null);
  const [image] = images;

  const onSubmit = (quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInputs) => {
    editOrderRow(orderRow.id, {
      quantity,
      unitPrice,
      inputValues,
      optionSelectionPicks,
      productFieldInputs
    })
  }

  return (
    <tr>
      <td>
        {
          image &&
          <div style={{
            backgroundImage: `url(${image && image.processedUrls.default})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "80px",
            height: "80px",
          }}></div>
        }
      </td>

      <td>
        <h3>
          {
            productArticle.isActive ?
              <Link to={routes.PRODUCT_DETAIL.get(productArticle.product.identifier, productArticle.identifier)}>
                <ProductArticleDisplayName article={productArticle} articleAttributes={productArticleAttributes} />
              </Link> :
              <span>
                <ProductArticleDisplayName article={productArticle} articleAttributes={productArticleAttributes} /> <span className="font-weight-normal">{t('table.column.name.archivedInfo')}</span>
              </span>
          }
        </h3>

        <ul className="list-unstyled mb-1">
          <li key="articleNumber">
            <span>{t('table.column.articleNumber')}: </span>
            <span>{productArticle.articleNumber}</span>
          </li>
          {optionSelectionPicks.map(({ summary: { name, value } }) => (
            <li key={name}>
              <OrderRowDetailLabel value={name} />
              <OrderRowDetailValue value={value} />
            </li>
          ))}
          {inputValues.filter(x => x.name && x.value).map(({ inputId, name, value }) => (
            <li key={inputId}>
              <OrderRowDetailLabel value={name} />
              <OrderRowDetailValue value={value} />
            </li>
          ))}

          {productFieldInputs.map(({ productFieldId, ...input }) => (
            <li key={`product-field-input-${productFieldId}`}>
              <ProductFieldLabel productFieldId={productFieldId} />
              <ProductFieldValue productFieldId={productFieldId} input={input} />
            </li>
          ))}
        </ul>

        {
          !useExtended &&
          <ul className="list-unstyled mb-1">
            <li>{t('table.column.unitPrice')}: {formatPrice(orderRow.unitPrice, 'kr')}</li>
            <li>{t('table.column.quantity')}: {t('row.quantity', { count: quantity })}</li>
          </ul>
        }
      </td>

      {
        useExtended &&
        <td className="text-right">{t('row.quantity', { count: quantity })}</td>
      }
      {
        useExtended &&
        <td className="text-right">
          {
            orderRow.unitPrice &&
            <div>{formatPrice(orderRow.unitPrice, 'kr')}</div>
          }
        </td>
      }
      {
        isEditable &&
        <td className="text-right">
          <OrderRowEditModal
            onSubmit={onSubmit}
            article={productArticle}
            articleAttributes={productArticleAttributes}
            orderRow={orderRow}
          />
          <button className="btn btn-sm btn-outline-dark ml-1" onClick={() => removeRow(id)}><i className="fa fa-trash" /></button>
        </td>
      }

    </tr>
  )
}));


export const OrderRowTable = connect(({ client }) => ({
  useExtended: client.greaterThan.small
}))(translate('order')(function _OrderRowTable({ orderRows, productArticles, productAttributes, removeRow, useExtended, isEditable, showTableHead = true, t }) {

  const _getRowProps = (row) => {
    const productArticle = find(productArticles, pa => pa.id == row.articleId);
    const productArticleAttributes = productAttributes.filter(pa => productArticle.attributeValueIds[pa.id] !== undefined);

    return {
      productArticle,
      productArticleAttributes,
      orderRow: row,
    }
  }

  return (
    <div>
      <table className='table'>
        {
          showTableHead && (
            <thead>
              <tr>
                <th>{t('table.column.article')}</th>
                <th>{t('table.column.description')}</th>
                {
                  useExtended && <th className="text-right">{t('table.column.quantity')}</th>
                }
                {
                  useExtended && <th className="text-right">{t('table.column.unitPrice')}</th>
                }
                {
                  isEditable &&
                  <th className="text-right"></th>
                }
              </tr>
            </thead>
          )
        }

        <tbody>
          {orderRows.map((row) => (
            <OrderRow
              useExtended={useExtended}
              key={row.id}
              removeRow={removeRow}
              {..._getRowProps(row)}
              isEditable={isEditable}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}));

export default connect(({ productAttributes }) => {
  return {
    productAttributes
  }
}, {
    resetOrderRows,
    removeOrderRow
  })(waitUntilPromiseIsResolved(
    ({ orderRows }) => {

      if (orderRows.length > 0) {
        return fetchProductArticles({
          articleIds: orderRows.map(row => row.articleId)
        })
      }
      else {
        return new Promise((resolve, reject) => resolve({ items: [] }));
      }
    },
    (data, props) => {
      return {
        productArticles: data.items
      }
    }
  )(translate('order')(class _OrderRows extends React.Component {
    constructor(props) {
      super(props);
      this.resetOrderRows = this.resetOrderRows.bind(this);
      this.removeOrderRow = this.removeOrderRow.bind(this);
    }

    resetOrderRows() {
      if (confirmDialog(this.props.t('rows.reset.confirm'))) {
        this.props.resetOrderRows();
      }
    }

    removeOrderRow(rowId) {
      if (confirmDialog(this.props.t('rows.remove.confirm'))) {
        this.props.removeOrderRow(rowId);
      }
    }

    render() {

      const { t, orderRows, productArticles, productAttributes, isEditable = false } = this.props;
      const sum = orderRows.filter(row => Boolean(row.unitPrice)).reduce((accum, { quantity, unitPrice }) => accum + (quantity * unitPrice), 0);

      return (
        <div>
          <OrderRowTable
            orderRows={orderRows}
            productArticles={productArticles}
            productAttributes={productAttributes}
            removeRow={this.removeOrderRow}
            isEditable={isEditable}
          />

          <div className="text-center text-sm-left">
            <div className="h5">{t('rows.sum.label')}: {formatPrice(sum, 'kr')}</div>

            {
              isEditable &&
              <button className="btn btn-sm btn-outline-dark" onClick={this.resetOrderRows}>
                <i className="fa fa-close" /> {t('rows.resetButton.label')}
              </button>
            }
          </div>

        </div>
      );
    }
  })));