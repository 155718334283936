import React from 'react';
import { connect } from 'react-redux';

import BlockArea from '../../containers/BlockArea';
import Markdown from '../Markdown';
import { withTranslation as translate } from 'react-i18next';

import find from 'lodash/find';
import every from 'lodash/every';

import { formatWeight } from '../../utils/content';
import { caseSelector } from '../../utils/helpers';

import {
  attributeValueIdsToString
} from '../../selectors/selectedProduct';

import ProductPrice from './ProductPrice';

const ProductFieldsAdditionalPriceCases = connect(({ productFields }, { productFieldIds }) => ({
  productFields: productFields.filter(x => productFieldIds.indexOf(x.id) !== -1)
}))(function _ProductFieldsAdditionalPriceCases({ productFields }) {

  const priceCases = productFields.reduce((allPriceCases, productField) => [
    ...allPriceCases,
    ...(productField.additionalPriceCases || [])
  ], []);

  return (
    priceCases.length > 0 &&
    <div>
      <h3>Tillval</h3>

      <ul>
        {priceCases.map(({ description }, priceCaseIndex) => (
          description && (
            <li key={priceCaseIndex}>
              <Markdown content={description} markdownOptions={{ headerLevelStart: 4 }} />
            </li>
          )
        ))}
      </ul>
    </div>
  )
})

const DetailList = ({ details }) => (
  <ul>
    {details.map(({ id, label, value }) => (
      <li key={id}>
        <span>{label}</span> <strong>{value}</strong>
      </li>
    ))}
  </ul>
)

const ProductFees = ({ fees }) => (
  <div>
    {fees.map(({ description }, index) => (
      description && <Markdown key={`fee-${index}`} content={description} />
    ))}
  </div>
)

const ProductArticleStockStatus = translate('product')(({ article, t }) => {
  const InStock = () => (
    <span>
      <i className="fa fa-check text-success" />
      {t('description.stockStatus.inStock')}
    </span>
  );

  return (
    <div>
      {caseSelector({
        'product--stock_item': (
          article.inStock ?
            <InStock /> :
            t('description.stockStatus.stockItem.backInStock', { date: article.backInStock })
        ),
        'product--order_item': (
          t('description.stockStatus.orderItem.estimatedDeliveryTime', { text: article.estimatedDeliveryTime })
        ),
        'consu--order_item': (
          t('description.stockStatus.orderItem.estimatedDeliveryTime', { text: article.estimatedDeliveryTime })
        ),
        'product--no_repurchase': (
          t('description.stockStatus.noRepurchase.left', { count: article.numInStock })
        ),
      }, <InStock />)(`${article.type}--${article.purchaseRule}`)}
    </div>
  )
});

const ProductArticlesStockStatus = ({ productName, articles, attributes }) => {

  const firstArticle = articles[0];
  const allSharesStockStatus = every(articles.map(({ type, purchaseRule, inStock, estimatedDeliveryTime, numInStock }) => (
    firstArticle.type === type &&
    (
      firstArticle.purchaseRule === purchaseRule &&
      purchaseRule !== 'no_repurchase'
    ) &&
    (firstArticle.purchaseRule !== 'stock_item' || firstArticle.inStock === inStock) &&
    (firstArticle.purchaseRule !== 'order_item' || firstArticle.estimatedDeliveryTime === estimatedDeliveryTime)
  )));

  return (
    <div>
      {
        allSharesStockStatus ?
          <ProductArticleStockStatus article={firstArticle} /> :
          <table className="table table-compact">
            <tbody>
              {articles.map(article => (
                <tr key={article.id}>
                  <td>{
                    articles.length > 1 ?
                      attributeValueIdsToString(article.attributeValueIds, attributes, false) :
                      productName
                  }</td>
                  <td className="text-right">
                    <ProductArticleStockStatus article={article} />
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
      }
    </div>
  )
}

export default translate('product')(function ({ id, slug, name, mainCategoryId, description, fees, article, articles, attributes, minimumOrderAmount, properties, productFields, t }) {

  const allFacts = [...properties, ...article.properties].filter(p => p.type === 'fact');

  const blockConditions = [
    `page:product`,
    `product:${slug}`,
    `product-category:${mainCategoryId}`
  ];
  const blockContext = { productName: name };

  const productFieldIds = productFields.map(x => x.productFieldId);

  return (
    <div>
      <h2>{t('description.header')}</h2>
      <div>
        <BlockArea
          areaSlug="main-top"
          conditions={blockConditions}
          context={blockContext}
        />

        {
          description &&
          <Markdown content={description} />
        }

        {
          article.description &&
          <Markdown content={article.description} />
        }
      </div>

      <div className="mt-4">
        <h2>{t('description.price.header')}</h2>
        <ProductPrice
          price={article.price}
          minimumOrderAmount={minimumOrderAmount}
        />
        {
          minimumOrderAmount > 1 &&
          <p>{t('description.minimumOrderAmount.description', { count: minimumOrderAmount })}</p>
        }
        <ProductFees fees={fees} />
        <ProductFieldsAdditionalPriceCases productFieldIds={productFieldIds} />
      </div>

      <div className="mt-4">
        <h3>{t('description.stockStatus.header')}</h3>
        <ProductArticlesStockStatus
          productName={name}
          articles={articles}
          attributes={attributes}
        />
      </div>


      <div className="mt-4">

        <h3>{t('description.facts.header')}</h3>
        <DetailList
          details={[
            {
              id: `article-${id}`,
              label: t('articleNumber'),
              value: article.articleNumber
            },
            ...Object.keys(article.attributeValueIds).map(attributeId => {
              attributeId = Number(attributeId);
              const attribute = find(attributes, a => a.id === attributeId);
              const attributeValue = find(attribute.values, av => av.id === article.attributeValueIds[attributeId]);

              return {
                id: attributeValue.id,
                label: attribute.name,
                value: attributeValue.name,
              }
            }),
            ...allFacts.map(({ id, name, value, unit }) => ({
              id,
              label: name,
              value: `${value} ${unit}`
            })),
            {
              id: `article-weight`,
              label: t('weight'),
              value: formatWeight(article.weight)
            }
          ]}
        />
      </div>

      <BlockArea
        areaSlug="main"
        conditions={blockConditions}
        context={blockContext}
      />

    </div>
  );
})