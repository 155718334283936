import { takeLatest, put, call, fork, select, all, delay } from 'redux-saga/effects'

import find from 'lodash/find'
import { dataURItoBlob } from 'utils/file'

import * as actions from 'actions'
import {
  fromOrderStateToSyncableOrder
} from 'actions/order'

import {
  getFieldSelections
} from './product'

import {
  // fetchProductArticles,
  orderApi,
  uploadUserGraphics
} from 'api'

import ga from '../ga'

import {
  getOrderRowBundles
} from 'selectors'

import {
  MODAL_COMPONENTS
} from 'components/modals/ModalFactory'

import {
  getLast
} from 'utils/array'

function* openAddedOrderRowModal() {
  const { product, orderRow } = yield select(state => ({
    product: state.selectedProduct,
    orderRow: getLast(state.order.rows)
  }))

  yield put(actions.addModal(
    MODAL_COMPONENTS.ADDED_ORDER_ROW,
    {
      product,
      orderRow
    }
  ))
}

function* onAddedOrderRow() {
  yield fork(openAddedOrderRowModal)
}

function* getTempOrder({ customer, data }) {
  yield put(actions.requestTempOrder(item))
  const { item } = yield call(orderApi.tempOrder, customer, data)
  yield put(actions.receiveTempOrder(item))
}

function* uploadLocalFiles() {

  const {
    order,
    userSettings,
    customer
  } = yield select(state => state)

  const localFileBundles = userSettings.localFiles
    .map(
      (x) => ({
        ...x,
        blob: dataURItoBlob(x.dataUrl)
      })
    )

  const localFileIdToSelectionIdMap = {}

  for (let i in localFileBundles) {
    const { blob, id, name, selectionTemplateId } = localFileBundles[i]

    const result = yield call(uploadUserGraphics, {
      file: blob,
      id,
      name,
      selectionTemplateId,
      customerId: customer.id,
    })

    localFileIdToSelectionIdMap[id] = result.selectionId
  }

  yield getFieldSelections()

  for (let i in order.rows) {
    const orderRow = order.rows[i]

    let orderRowNeedsToBeUpdated = false
    const mappedProductFieldInputs = orderRow.productFieldInputs.map((productFieldInput) => {
      const _orderRowNeedsToBeUpdated = productFieldInput.value && localFileIdToSelectionIdMap[productFieldInput.value] !== undefined
      orderRowNeedsToBeUpdated = orderRowNeedsToBeUpdated || _orderRowNeedsToBeUpdated

      if (_orderRowNeedsToBeUpdated) {
        return {
          ...productFieldInput,
          value: undefined,
          selectionId: localFileIdToSelectionIdMap[productFieldInput.value]
        }
      }

      return productFieldInput
    })

    if (orderRowNeedsToBeUpdated) {
      yield put(actions.editOrderRow(
        orderRow.id, {
        productFieldInputs: mappedProductFieldInputs
      }
      ))
    }
  }

  for (let i in userSettings.fieldInputs) {
    const { fieldId, ...fieldInput } = userSettings.fieldInputs[i]
    const needsToBeUpdated = fieldInput.value && localFileIdToSelectionIdMap[fieldInput.value] !== undefined

    if (needsToBeUpdated) {
      yield put(actions.fieldInput(
        fieldId,
        {
          ...fieldInput,
          selectionId: localFileIdToSelectionIdMap[fieldInput.value],
          value: undefined
        }
      ))
    }

  }


  for (let localFileId in localFileIdToSelectionIdMap) {
    yield put(actions.removeLocalFile(localFileId))
  }

  yield put(actions.localFilesHasBeenUploaded())
}

function* createOrderAndStartPaymentAndWaitForResolvedPayment() {

  yield put(actions.setSubmitState('STARTED'))
  yield delay(100)

  const {
    customer,
    userIsSignedIn,
    user
  } = yield select(state => ({
    customer: state.customer,
    userIsSignedIn: state.auth.isSignedIn,
    user: state.user,
  }))

  yield put(actions.setSubmitState('STARTED_LOCAL_FILES_UPLOAD'))
  yield delay(100)
  yield uploadLocalFiles()

  let orderId = null

  const {
    order,
    orderRowBundles,
  } = yield select(state => ({
    order: state.order,
    orderRowBundles: getOrderRowBundles(state),
  }))

  try {

    yield put(actions.setSubmitState('STARTED_ORDER_CREATE'))
    yield delay(100)

    const { createdId } = yield call(
      orderApi.createOrder,
      order.customerId,
      fromOrderStateToSyncableOrder(order)
    )

    orderId = createdId
  } catch (error) {
    console.warn("-error-", error)
    return false
  }

  try {
    yield put(actions.setSubmitState('STARTED_PAYMENT'))
    // yield delay(5*60000)
    yield call(orderApi.startOrderPayment, order.customerId, orderId)
  } catch (error) {
    console.warn("-error-", error)
    yield put(actions.setSubmitState('PAYMENT_ERROR'))
    yield call(orderApi.removeOrder, order.customerId, orderId)
    return false
  }

  let count = 0
  let paymentState = null

  while (true) {
    yield delay(2000)
    try {
      const { item } = yield call(orderApi.getOrderPaymentState, order.customerId, orderId)
      paymentState = item

      if (paymentState.state !== 'PENDING') { // Done!
        break
      }
    } catch (error) {
      return
    }
    count++

    if (count > 60) { // Two minutes = 60 * 2000
      break
    }
  }

  switch (paymentState.state) {
    case 'DECLINED':
      yield put(actions.setSubmitState('PAYMENT_DECLINED'))
      yield call(orderApi.removeOrder, order.customerId, orderId)
      return
    case 'ERROR':
      yield put(actions.setSubmitState('PAYMENT_ERROR'))
      yield call(orderApi.removeOrder, order.customerId, orderId)
      return
    case 'RESOLVED':
      yield put(actions.setSubmitState('PAYMENT_RESOLVED'))
      // Continue underneath ..
      break
  }

  yield delay(1)

  const deliveryMethod = find(order.availableDeliveryMethods, dm => dm.id === order.deliveryMethodId)

  try {
    ga.purchase(
      orderId,
      order.amount.total,
      order.amount.tax,
      deliveryMethod ? deliveryMethod.price : undefined,
      orderRowBundles
    )
  } catch (error) {
    console.warn("GA Purchase Event Error", error)
  }

  yield put(actions.setSubmitState(null))

  yield put(actions.addModal(
    MODAL_COMPONENTS.ORDER_WAS_SENT,
    {
      order,
      customer
    }
  ))

  yield put(actions.resetOrder())

  if (userIsSignedIn) {
    yield put(actions.setAndFetchCustomer(customer.id))
  }
  else {
    yield put(actions.resetCustomer())
  }
}

export default function* orderSaga() {
  yield all([
    // takeLatest(actions.ORDER.CREATE_SEND_AND_RESET, createSendAndResetOrder),
    takeLatest(
      actions.ORDER.CREATE_ORDER_AND_START_PAYMENT_AND_WAIT_FOR_RESOLVED_PAYMENT,
      createOrderAndStartPaymentAndWaitForResolvedPayment
    ),
    takeLatest(actions.ORDER.GET_TEMP, getTempOrder),
    takeLatest(actions.ORDER.ROWS.ADD, onAddedOrderRow),
    takeLatest(actions.ORDER.UPLOAD_LOCAL_FILES, uploadLocalFiles)
  ])
}