import request from 'superagent'

import { getFromStorage } from '../utils/storage'
import { cleanObject } from '../utils/object'

import config from '../config'

const getApiToken = () => getFromStorage(config.STORAGE_TOKEN_KEY, config.API_DEFAULT_TOKEN)
const buildApiUrl = (endpoint) => `${config.API_URL}/${endpoint}`

const buildQuery = (queryObj) => {
  let _query = {}

  for (var key in queryObj) {
    let val = queryObj[key]

    if (Array.isArray(val)) {
      val = val.join(",")
    }

    if (typeof val === 'object') {
      val = JSON.stringify(val)
    }

    const table = {
      true: 1,
      false: 0,
      null: undefined,
      "": undefined
    }

    const valStr = String(val)

    if (valStr in table) {
      val = table[valStr]
    }

    if (val !== undefined) {
      _query[key] = val
    }
  }

  return _query
}


export const apiRequest = (endpoint, { method = 'GET', data = null, files = null, query = {}, requiresTokenAuthorization = false, token = undefined, additionalHeaders = {} } = {}) => {
  let req = request(method, buildApiUrl((endpoint)))

  req = req.query(buildQuery(query))

  if (files !== null) {
    if (data !== null) {
      const builtData = buildQuery(data)
      req = req.field(builtData)
    }

    for (let fileName in files) {
      const file = files[fileName]
      req = req.attach(fileName, file)
    }
  }
  else if (data !== null) {
    const encodedData = encodeURIComponent(JSON.stringify(data))
    req = req.send(`data=${encodedData}`)
  }

  const methodsThatRequireAuthorization = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

  if (methodsThatRequireAuthorization.indexOf(method) !== -1 || requiresTokenAuthorization) {
    req = req.set('Authorization', `Bearer ${token || getApiToken()}`)
  }

  for (let headerKey in additionalHeaders) {
    req = req.set(headerKey, additionalHeaders[headerKey])
  }

  return req
}


export const catchApiRequestError = (req) => {
  return req.catch((...args) => {
    const encodedArgument = JSON.stringify(args)
    throw new Error(`-api request error-: ${encodedArgument}`)
  })
}


const _defaultSelector = (body) => body.data

const apiGet = (endpoint, query = {}, { requiresTokenAuthorization } = { requiresTokenAuthorization: false }, selector = _defaultSelector) => {
  return new Promise((resolve, reject) => {
    apiRequest(endpoint, {
      method: 'GET',
      query,
      requiresTokenAuthorization,
    }).then(
      ({ body }) => {
        resolve(selector(body))
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

const fetchItems = apiGet

export const fetchActivities = (filters = {}) => fetchItems('activities', { levels: 5, ...filters })
export const fetchProductCategories = (filters = {}) => fetchItems('product-categories', { levels: 5, ...filters })

export const fetchProducts = (filters = {}, extended = false) => {
  return fetchItems('products', {
    limit: 100,
    extended,
    ...filters
  })
}

export const fetchProductArticles = (filters = {}) => {
  return fetchItems('product-articles', {
    limit: 100,
    ...filters
  })
}

export const fetchProduct = (productIdentifier, articleIdentifier = null, imageVariants = {}) => {
  return new Promise((resolve, reject) => {
    apiRequest(
      `products/${productIdentifier}${articleIdentifier !== null ? `/${articleIdentifier}` : ""}`, {
      method: 'GET',
      query: {
        includeOptions: 0,
        imageVariants: imageVariants,
        productFieldMode: 'none',
      }
    }).then(
      ({ body }) => {
        resolve(body.data.item)
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

export function fetchArticlePriceBundle(articleId, { productFieldInputs, quantity = 1 }) {
  return apiGet(`articles/${articleId}/price-bundle`, {
    data: {
      productFieldInputs,
      quantity
    }
  })
}


export const fetchProductAttributes = () => fetchItems('product-attributes', {})

export const fetchProductOptions = (optionIds, imageVariants) => {
  return fetchItems('product-options', {
    optionIds,
    imageVariants
  })
}

export const fetchProductOption = (optionId) => {
  return new Promise((resolve, reject) => {
    apiRequest(
      `product-options`, {
      method: 'GET',
      query: {
        optionIds: [optionId]
      }
    }).then(
      ({ body }) => {
        resolve(body.data.items.pop())
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

export function fetchProductCollections(ids) {
  return fetchItems('product-collections', {
    productCollectionIds: ids
  })
}

export function fetchProductCollection(id) {
  return new Promise((resolve, reject) => {
    fetchProductCollections([id])
      .then(data => resolve(data.items[0]))
      .catch(resp => {
        reject(resp)
      })
  })
}

export const fetchBlocks = (areaSlugs = [], conditions = [], context = {}, testMode = false) => {
  if (testMode) {
    return Promise.resolve([])
  }

  return new Promise((resolve, reject) => {
    apiRequest("blocks", {
      query: {
        conditions,
        areaSlugs,
        context: JSON.stringify(context)
      },
    }).then(
      ({ body }) => {
        resolve(body.data.items)
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

export const fetchBlockPages = () => fetchItems('block-pages')

export const fetchGrids = (areaSlugs = [], conditions = []) => fetchItems('grids', { areaSlugs, conditions });

export const fetchCustomers = (filters = {}) => fetchItems(
  'customers',
  {
    limit: 10,
    ...filters
  },
  true
)

const apiPost = (endpoint, { data = null, files = null }) => {
  return new Promise((resolve, reject) => {
    apiRequest(endpoint, {
      method: 'POST',
      data,
      files,
      requiresTokenAuthorization: true,
    }).then(
      ({ body }) => {
        resolve(body.data)
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

const apiPatch = (endpoint, { data = {} }) => {
  return new Promise((resolve, reject) => {
    apiRequest(endpoint, {
      method: 'PATCH',
      data,
      requiresTokenAuthorization: true,
    }).then(
      ({ body }) => {
        resolve(body !== null ? body.data : {})
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

const apiDelete = (endpoint) => {
  return new Promise((resolve, reject) => {
    apiRequest(endpoint, {
      method: 'DELETE',
      data: {},
      requiresTokenAuthorization: true,
    }).then(
      ({ body }) => {
        resolve(body !== null ? body.data : {})
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

const createCustomer = (customerData = {}) => apiPost(
  `customers`,
  { data: customerData }
)

const createCustomerAddress = (customerId, data) => createCustomer({
  ...data,
  infoType: 'address',
  customerId
})

const editCustomer = (customerId, customerData = {}) => apiPatch(
  `customers/${customerId}`,
  { data: customerData }
)

const getCustomer = (customerId) => apiGet(
  `customers/${customerId}`,
  {}, { requiresTokenAuthorization: true },
)

const createOrder = (customerId, orderData = {}) => apiPost(
  `customers/${customerId}/orders`,
  { data: orderData }
)

const tempOrder = (customer, orderData = {}) => apiPost(
  `customers/${customer.id || customer.type || 'generic'}/temp-order`,
  { data: orderData }
)

const editOrder = (customerId, orderId, orderData = {}) => apiPatch(
  `customers/${customerId}/orders/${orderId}`,
  { data: orderData }
)

export function getOrders(customerId) {
  return apiGet(
    `customers/${customerId}/orders`,
    {}, { requiresTokenAuthorization: true },
  )
}

export function getOrder(customerId, orderId) {
  return apiGet(
    `customers/${customerId}/orders/${orderId}`,
    {}, { requiresTokenAuthorization: true },
  )
}

const removeOrder = (customerId, orderId) => apiDelete(
  `customers/${customerId}/orders/${orderId}`
)

const addOrderRow = (customerId, orderId, rowsData) => apiPost(
  `customers/${customerId}/orders/${orderId}/rows`,
  { data: rowsData }
)

const editOrderRow = (customerId, orderId, rowId, rowData) => apiPatch(
  `customers/${customerId}/orders/${orderId}/rows/${rowId}`,
  { data: rowData }
)

const removeOrderRow = (customerId, orderId, rowId) => apiDelete(
  `customers/${customerId}/orders/${orderId}/rows/${rowId}`
)

const startOrderPayment = (customerId, orderId) => apiPatch(
  `customers/${customerId}/orders/${orderId}/start-payment`,
  {}
)

const getOrderPaymentState = (customerId, orderId) => apiGet(
  `customers/${customerId}/orders/${orderId}/payment-state`,
)

const sendOrder = (customerId, orderId, orderData = {}) => apiPatch(
  `customers/${customerId}/orders/${orderId}/send`,
  { data: orderData }
)

const payOrder = (customerId, orderId, paymentData = {}) => apiPatch(
  `customers/${customerId}/orders/${orderId}/register-payment`,
  { data: paymentData }
)

export default {
  request: apiRequest,
  catcher: catchApiRequestError,
}

export const orderApi = {
  createCustomer,
  createCustomerAddress,
  editCustomer,
  getCustomer,
  createOrder,
  tempOrder,
  editOrder,
  removeOrder,
  getOrder,
  addOrderRow,
  editOrderRow,
  removeOrderRow,
  sendOrder,
  payOrder,
  startOrderPayment,
  getOrderPaymentState,
}

export const getToken = (username, password) => new Promise((resolve, reject) => {
  apiRequest('auth/get_token', {
    method: 'POST',
    requiresTokenAuthorization: false,
  }).auth(username, password).then(
    ({ body }) => {
      resolve(body.data)
    }
  ).catch((resp) => {
    reject(resp)
  })
})

export function testToken(tokenToBeTested) {
  return new Promise((resolve, reject) => {
    apiRequest('auth/test_token', {
      method: 'GET',
      requiresTokenAuthorization: true,
      token: tokenToBeTested
    }).then(
      ({ body }) => {
        resolve({
          ...body.data,
          result: 'valid'
        })
      }
    ).catch((resp) => {
      reject(resp)
    })
  })
}

export function addNewsletterSubscriber(email) {
  return apiPost('newsletter/default/subscribers', { data: { email } })
}


function searchSelector(body) {
  return body.data.hits.map(
    ({ _id, _score, _source }) => ({
      id: Number(_id),
      _score,
      ..._source,
    })
  )
}

export const search = (queryString, includeAll = false) => apiGet('search', {
  q: queryString,
  includeAll
})


export const searchUnionNodes = (filters = {}) => apiGet('search/union-nodes', {
  ...filters
}, undefined, searchSelector)


/* ---- USER STUFF ---- */

export function fetchUser() {
  return apiGet('user', undefined, { requiresTokenAuthorization: true })
}

export function createUser(data) {
  return apiPost('user', { data })
}

export function setPassword(password, token) {
  return new Promise((resolve, reject) => {
    apiRequest('user/set-password', {
      method: 'PATCH',
      requiresTokenAuthorization: true,
      token: token,
      data: {
        password
      }
    })
      .then(resolve)
      .catch(reject)
  })

  // return apiPatch('user/set-password', {
  //   password
  // })
}

export function testEmail(email) {
  return apiPost('test-email', { data: { email } })
}

export function testCustomersCode(customerIds, code) {
  return apiPost('test-code', {
    data: {
      customerIds,
      code
    }
  })
}

export function sendPasswordReset(email) {
  return apiPost('send-password-reset', {
    data: {
      email
    }
  })
}

export function sendCode(email, customerIds) {
  return apiPost('send-code', {
    data: {
      email,
      customerIds
    }
  })
}

export function addCustomerAccess(customerId, accessLevel = 'own') {
  return apiPost('user/customer-access', {
    data: {
      customerId,
      accessLevel
    }
  })
}

export function removeCustomerAccess(id) {
  return apiDelete(`user/customer-access/${id}`)
}

export function selectCustomer(id) {
  return apiPatch(`user/select-customer`, {
    data: {
      customerId: id
    }
  })
}

// TODO: Not implemented
export function testPassword(password) {
  return apiPatch('user/test-password', {
    data: {
      password
    }
  })
}

export function uploadUserGraphics({ id, name, file, selectionTemplateId, customerId }) {
  return apiPost(`user/graphics`, {
    files: {
      image: file
    },
    data: {
      id,
      name,
      selectionTemplateId,
      customerId
    }
  })
}

export function fetchProductFields({ ids = null, mode = 'shallow' } = {}) {
  return apiGet('product-fields', cleanObject({
    ids,
    mode
  }))
}

export function fetchFieldSelections(selectedCustomerId = null) {
  return apiGet('field-selections', cleanObject({
    selectedCustomerId
  }))
}

export function deleteFieldSelection(id) {
  return apiDelete(`field-selections/${id}`)
}

export function fetchTags() {
  return apiGet('tags')
}

export function fetchTagCategories() {
  return apiGet('tag-categories')
}

export function fetchCustomerEvents(customerId) {
  return apiGet(`customers/${customerId}/events`)
}

export function createCustomerEvent(customerId, eventData) {
  return apiPost(
    `customers/${customerId}/events`,
    {
      data: eventData,
    }
  )
}

export function editCustomerEvent(customerId, eventId, eventData) {
  return apiPatch(
    `customers/${customerId}/events/${eventId}`,
    {
      data: eventData,
    }
  )
}

export function removeCustomerEvent(customerId, eventId) {
  return apiDelete(`customers/${customerId}/events/${eventId}`)
}

export function fetchCustomerEventPriceTypeProducts() {
  return apiGet('customer-event-price-type-products')
}