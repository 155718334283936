import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import { Redirect, Route, Link } from 'react-router-dom';

import { hasValidAddress } from 'utils/content';
import { cleanArray } from 'utils/array';
import { createUniqueId } from 'utils/id'
import { blobToDataUri } from 'utils/file'

import { UserTranslate } from 'containers/translates';
import { connectFactory } from "containers/Connect";
import promiseHandler from 'containers/hoc/promiseHandler';
import { Translate } from 'containers/translates';

import {
  getFieldSelectionDisplay,
  getFieldSelectionImageUrl,
  getUserFieldSelections
} from 'selectors'

import {
  addCustomerAccess,
  signout,
  refreshUser,
  resetCustomer,
  removeLocalFile,
  deleteFieldSelections,
  refreshFieldSelections,
  addLocalFile
} from 'actions';


import {
  orderApi,
} from 'api';


import routes from 'routes';


import BlockArea from '../BlockArea';

import { OneColumnLayout } from 'components/layouts';
import DocumentMeta from 'components/documentMeta';
import { Sheet } from 'components/layouts';

import UserNewPasswordModal from 'components/modals/UserNewPasswordModal';
import SelectCustomerModal from 'components/modals/SelectCustomerModal';
import SetCustomerModal from 'components/modals/SetCustomerModal'; // Admin

import Modal from 'components/modals/Modal';
import ModalButton from 'components/modals/ModalButton';

import OrderList from 'components/OrderList';
import LoadingIndicator from 'components/spinners';

import { DangerButton, DarkButton, ButtonContainer, Button } from 'components/buttons';
import {
  SigninIcon,
  EditIcon,
  UserIcon,
  StaffIcon,
  DashboardIcon,
  OrderIcon,
  ImageIcon,
  EventIcon
} from 'components/Icon';


import ContentHeader from 'components/ContentHeader';
import CustomerForm from "components/forms/CustomerForm";
import { PageHeading } from 'components/content';
import CreateLocalSelectionForm from 'components/forms/CreateLocalSelectionForm'
import Events from 'components/Event/Events';

const SignoutConnect = connectFactory({ signout: signout });
const AddCustomerAccessConnect = connectFactory({ addCustomerAccess: addCustomerAccess });


function UserHavePendingAdminAccess() {
  return (
    <BlockArea areaSlug="inline" conditions={["userHavePendingAdminCustomerAccess"]} defaultContent={
      <div className="alert alert-info">
        <UserTranslate>
          {({ t }) => (
            <p>{t('applyForCustomerAdmin.pendingAccess.message')}</p>
          )}
        </UserTranslate>
      </div>
    } />
  )
}

function SelectCustomerModalButton() {
  return (
    <ModalButton
      ModalComponent={SelectCustomerModal}
      ButtonComponent={({ onClick }) => (
        <DarkButton color='dark' onClick={onClick}>
          <EditIcon fixedWidth />
          <span className="mr-1">
            <UserTranslate>
              {({ t }) => t('selectCustomer.button.label')}
            </UserTranslate>
          </span>
        </DarkButton>
      )}
    />
  )
};

function UserNewPasswordModalButton() {
  return (
    <ModalButton
      ModalComponent={UserNewPasswordModal}
      ButtonComponent={({ onClick }) => (
        <DarkButton color='dark' onClick={onClick}>
          <EditIcon fixedWidth />
          <span className="mr-1">
            <UserTranslate>
              {({ t }) => t('changePassword.button.label')}
            </UserTranslate>
          </span>
        </DarkButton>
      )}
    />
  )
};

function SignoutButton() {
  return (
    <SignoutConnect>
      {({ signout }) => (
        <DangerButton onClick={() => signout()}>
          <SigninIcon fixedWidth />
          <span className="mr-1">
            <UserTranslate>
              {({ t }) => t('signOut.button.label')}
            </UserTranslate>
          </span>
        </DangerButton>
      )}
    </SignoutConnect>
  )
};

function ApplyForAdminButton({ customerId }) {
  return (
    <AddCustomerAccessConnect>
      {({ addCustomerAccess }) => (
        <DarkButton onClick={() => addCustomerAccess(customerId, 'admin')}>
          <UserTranslate>
            {({ t }) => t('applyForCustomerAdmin.button.label')}
          </UserTranslate>
        </DarkButton>
      )}
    </AddCustomerAccessConnect>
  )
}

function CustomerFormModal({ onClose, customer, ...formProps }) {
  return (
    <Modal onClose={onClose} isOpen>
      <React.Fragment>
        <h2>{customer.name}</h2>
        <hr />
        <CustomerForm
          customer={customer}
          {...formProps}
        />
      </React.Fragment>
    </Modal>
  );
}

const ChangeUserCustomerModalButton = promiseHandler(connect((state) => ({
  userCustomer: state.user.customer
}), {
    refreshUser
  })(class _ChangeUserCustomerModalButton extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modalIsOpen: false
      }

      this.onSubmit = this.onSubmit.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
    }

    onSubmit(inputValues) {
      const { makePromise, refreshUser, userCustomer } = this.props;

      makePromise(
        orderApi.editCustomer(
          userCustomer.id,
          inputValues
        )
      ).then(() => {
        this.setState(
          () => ({ modalIsOpen: false }),
          refreshUser
        )
      });
    }

    openModal() {
      this.setState(() => ({ modalIsOpen: true }));
    }

    closeModal() {
      this.setState(() => ({ modalIsOpen: false }));
    }

    render() {
      const { userCustomer, t } = this.props;

      return (
        <Translate ns="editContactInfo">
          {({ t }) => (
            <React.Fragment>
              <DarkButton onClick={this.openModal}>
                <EditIcon fixedWidth />
                {t('button.label')}
              </DarkButton>
              {
                this.state.modalIsOpen &&
                <CustomerFormModal
                  customer={userCustomer}
                  fields={['mobile', 'address']}
                  onSubmit={this.onSubmit}
                  onClose={this.closeModal}
                  t={t}
                />
              }
            </React.Fragment>
          )}
        </Translate>
      )
    }
  }));


function UserAccount({ user, customer }) {
  const [adminAccess,] = (user.customerAccess || []).filter(x => x.customer.id === customer.id).filter(x => x.accessLevel === 'admin');
  const userHavePendingAdminAccess = adminAccess && adminAccess.state === 'pending';

  const userCustomer = user.customer;

  return (
    <Translate ns="account" context={{ userName: user.name }}>
      {({ t }) => (
        <React.Fragment>
          <h2>{t('header')}</h2>
          <hr />
          <Translate ns="user">
            {({ t }) => (
              <React.Fragment>
                <h3>{t('signedInAs.header')}</h3>
                <p>{t('signedInAs.description')}</p>
                <hr />
                <h3>{t('contactInfo.header')}</h3>
                <dl>
                  {
                    hasValidAddress(userCustomer) && (
                      <React.Fragment>
                        <dt>{t('contactInfo.address')}</dt>
                        <dd>{userCustomer.street}, {userCustomer.zip} {userCustomer.city}</dd>
                      </React.Fragment>
                    )
                  }

                  {
                    userCustomer.mobile && (
                      <React.Fragment>
                        <dt>{t('contactInfo.mobile')}</dt>
                        <dd>{userCustomer.mobile}</dd>
                      </React.Fragment>
                    )
                  }
                </dl>
                <ButtonContainer>
                  <ChangeUserCustomerModalButton t={t} />
                  <UserNewPasswordModalButton />
                  <SignoutButton />
                </ButtonContainer>
              </React.Fragment>
            )}
          </Translate>
          <hr />
          <Translate ns="customer" context={{ customerName: customer.name }}>
            {({ t }) => (
              <React.Fragment>
                <h3>{t('header')}</h3>
                <p>{(
                  customer.id !== null ?
                    t('description', { context: customer.userAccessLevel }) :
                    t('noCustomerSelectedDescription')
                )}</p>

                {
                  userHavePendingAdminAccess &&
                  <UserHavePendingAdminAccess />
                }

                <ButtonContainer>
                  {
                    (
                      customer.id &&
                      customer.userAccessLevel === 'own' &&
                      !userHavePendingAdminAccess
                    ) &&
                    <ApplyForAdminButton customerId={customer.id} />
                  }
                  <SelectCustomerModalButton />
                </ButtonContainer>

              </React.Fragment>
            )}
          </Translate>

        </React.Fragment>
      )}
    </Translate>
  )
}


function UserDashboard({ user, customer }) {
  return (
    <Translate ns="dashboard">
      {({ t }) => (
        <React.Fragment>
          <header>
            <h2>{t('header.heading')}</h2>
            <p>{t('header.post')}</p>
          </header>
          <hr />
          <div>
            <p>{t('moreToCome')}</p>
          </div>
        </React.Fragment>
      )}
    </Translate>
  )
}

function CustomerOrderTab({ customer }) {
  return (
    <React.Fragment>
      <OrderList customerId={customer.id} key={customer.id} />
    </React.Fragment>
  )
}

function EventsTab({ customer }) {
  return (
    <Events />
  )
}

const AddNewImageModalButton = connect((state) => ({
  selectionTemplates: state.fieldSelections.filter(({ isTemplate }) => isTemplate)
}), {
    addLocalFile
  })(function _AddNewImageModalButton({ selectionTemplates, onSubmit, addLocalFile }) {
    return (
      <Translate ns="creationForm">
        {({ t }) => (
          <ModalButton
            ModalComponent={({ onClose, ...props }) => (
              <Modal onClose={onClose} {...props}>

                <React.Fragment>
                  <ContentHeader />
                  <hr />
                  <CreateLocalSelectionForm
                    t={t}
                    onSubmit={({ selectionTemplateId, file, name }) => {
                      blobToDataUri(file)
                        .then(
                          (dataUrl) => {
                            const id = createUniqueId()
                            addLocalFile({
                              id,
                              name,
                              dataUrl,
                              selectionTemplateId
                            })
                            setTimeout(() => {
                              onSubmit()
                              onClose()
                            }, 100)
                          }
                        )

                    }}
                    selectionTemplates={selectionTemplates}
                  />
                </React.Fragment>

              </Modal>
            )}
            ButtonComponent={({ onClick }) => (
              <Button onClick={onClick}>
                {t('modalButton.label')}
              </Button>
            )}
          />
        )}
      </Translate>
    )
  })


const ImageRow = connect((state, { selectionId, value }) => ({
  selectionImageUrl: getFieldSelectionImageUrl(state, { selectionId, value }),
  selectionDisplay: getFieldSelectionDisplay(state, { selectionId, value }),
}))(class _ImageRow extends React.Component {

  state = {
    isBeenDeleted: false
  }

  handleRemove = () => {
    this.setState({
      isBeenDeleted: true
    }, this.props.onRemove)
  }

  render() {
    const {
      selectionId,
      selectionImageUrl,
      selectionDisplay,
    } = this.props

    const {
      isBeenDeleted
    } = this.state

    return (
      <tr
        style={isBeenDeleted ? {
          pointerEvents: "none",
          opacity: .5,
        } : {}}
      >
        <td>
          <img
            style={{
              width: 80,
              height: 80,
              objectFit: "contain",
              objectPosition: "center center"
            }}
            src={selectionImageUrl}
            alt="presentation"
          />
        </td>
        <td>{selectionDisplay}</td>
        <td className="text-right">
          <Button onClick={this.handleRemove}>
            <i className="fa fa-remove" />
          </Button>
        </td>
      </tr>
    )
  }
})

const ImagesTab = connect((state) => ({
  customerSelectionIds: getUserFieldSelections(state).map((x) => x.id),
  localFiles: state.userSettings.localFiles,
}), {
    removeLocalFile,
    deleteFieldSelections,
    refreshFieldSelections
  }
)(class _ImagesTab extends React.Component {

  render() {
    const {
      customerSelectionIds,
      localFiles
    } = this.props

    const selections = [
      ...localFiles.map((localFile) => ({
        selectionId: localFile.selectionTemplateId,
        value: localFile.id,
        onRemove: () => this.props.removeLocalFile(localFile.id)
      })),
      ...customerSelectionIds.map((selectionId) => ({
        selectionId,
        onRemove: () => this.props.deleteFieldSelections(selectionId)
      })),
    ]

    return (
      <Translate ns="images" context={{}}>
        {({ t }) => (
          <React.Fragment>
            <h2>{t('header')}</h2>
            <hr />
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t('table.file.column')}</th>
                    <th>{t('table.name.column')}</th>
                    <th className="text-right">{t('table.remove.column')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selections.map(({ selectionId, value, onRemove }, selectionIndex) => (
                    <ImageRow
                      key={value || selectionId}
                      selectionId={selectionId}
                      value={value}
                      onRemove={onRemove}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <AddNewImageModalButton
                onSubmit={this.props.refreshFieldSelections}
              />
            </div>
          </React.Fragment>
        )}
      </Translate>
    )
  }
})

function NullComponent() {
  return null;
}

const SetCustomerModalButton = connect(state => ({
  customer: state.customer
}))(({ customer }) => (
  <Translate ns="selectCustomer">
    {({ t }) => (
      <ModalButton
        ButtonComponent={({ onClick }) => (
          <DarkButton onClick={onClick}>
            {t('button.label', {
              context: customer.id !== null ? "change" : "select"
            })}
          </DarkButton>
        )}
        ModalComponent={SetCustomerModal}
        t={t}
      />
    )}
  </Translate>
));

const ResetCustomerButton = connect(null, {
  resetCustomer
})(({ resetCustomer }) => (
  <Translate ns="resetCustomer">
    {({ t }) => (
      <DangerButton onClick={resetCustomer}>
        {t('button.label')}
      </DangerButton>
    )}
  </Translate>
))

const StaffTab = connect(state => ({
  customer: state.customer
}))(function StaffTab({ customer }) {
  return (
    <Translate ns="staff">
      {({ t }) => (

        <React.Fragment>
          <ContentHeader />
          <hr />
          {
            customer.id !== null && (
              <p>
                {t('currentCustomer', { customerName: customer.data.name })}
              </p>
            )
          }
          <ButtonContainer>
            <SetCustomerModalButton />
            {
              customer.id !== null && (
                <ResetCustomerButton />
              )
            }
          </ButtonContainer>
        </React.Fragment>
      )}
    </Translate>
  )
})

class UserTabs extends React.Component {
  render() {
    const { user, customer, match } = this.props;
    const userAccessLevel = (
      customer.userAccessLevel ||
      customer.data && customer.data.userAccessLevel
    );

    const _equalsCurrentLocation = (location) => this.props.location.pathname === location;

    const tabLinkBundles = [
      { key: 'root', route: routes.USER.ROOT, iconComponent: DashboardIcon, visible: true },
      { key: 'customerOrders', route: routes.USER.CUSTOMER_ORDERS, iconComponent: OrderIcon, visible: userAccessLevel !== undefined },
      // { key: 'customerEvents', route: routes.USER.CUSTOMER_EVENTS, iconComponent: EventIcon, visible: true },
      { key: 'customerImages', route: routes.USER.CUSTOMER_IMAGES, iconComponent: ImageIcon, visible: user.id !== undefined },
      // {key: 'addresses', route: routes.USER.ADDRESSES, iconComponent: AddressIcon, visible: true },
      { key: 'account', route: routes.USER.ACCOUNT, iconComponent: UserIcon, className: 'ml-0 ml-md-auto', visible: user.id !== undefined },
      { key: 'staff', route: routes.USER.STAFF, iconComponent: StaffIcon, visible: user.type === 'staff' },
    ].filter(x => x.visible)

    const tabs = [
      {
        route: routes.USER.ROOT,
        component: UserDashboard,
        visible: true
      },
      {
        route: routes.USER.CUSTOMER_ORDERS,
        component: CustomerOrderTab,
        visible: userAccessLevel !== undefined,
      },
      {
        route: routes.USER.CUSTOMER_EVENTS,
        component: EventsTab,
        visible: true,
      },
      {
        route: routes.USER.CUSTOMER_IMAGES,
        component: ImagesTab,
        visible: user.id !== undefined
      },

      {
        route: routes.USER.STAFF,
        component: StaffTab,
        visible: user.type === 'staff'
      },

      {
        route: routes.USER.ACCOUNT,
        component: UserAccount,
        visible: user.id !== undefined
      }
    ].filter(x => x.visible)

    return (
      <Translate ns="user">
        {({ t }) => (
          <React.Fragment>
            <nav className="nav nav-tabs justify-content-center justify-content-md-start">
              {tabLinkBundles.map(({ key, route, iconComponent: IconComponent = NullComponent, className, visible, ...tabLinkProps }) => (
                <Link key={key} className={cleanArray([
                  'nav-item', 'nav-link',
                  (_equalsCurrentLocation(route.get()) ? 'active' : null),
                  className
                ]).join(" ")} to={route.get()} {...tabLinkProps}>
                  <IconComponent fixedWidth />
                  <span className="d-none d-md-inline">{t(`userNav.${key}.label`)}</span>
                </Link>
              ))}
            </nav>

            <Sheet className="p-4" style={{ borderTop: 'none' }}>
              {tabs.map(({ route, component: TabComponent, visible, ...tabComponentProps }, tabIndex) => (
                <Route
                  key={tabIndex}
                  path={route.route()}
                  exact
                  render={() => (
                    <TabComponent user={user} customer={customer} />
                  )}
                  {...tabComponentProps}
                />
              ))}
            </Sheet>
          </React.Fragment>
        )}
      </Translate>

    )
  }
}

const UserPage = connect(null, {
  refreshUser
})(class _UserPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasOpenedWizard: false,
      hasEndedUserCustomers: false
    }
  }

  render() {
    const {
      user,
      customer,
      match,
    } = this.props;

    return (
      <UserTranslate>
        {({ t }) => (
          <div>
            <DocumentMeta
              title={t('title')}
              description={t('description')}
              url={routes.USER.ROOT.get()}
              noindex
            />
            <OneColumnLayout>
              <header>
                <PageHeading>{customer.name}</PageHeading>
              </header>

              <div className="mt-4">
                <UserTabs {...this.props} />
              </div>
            </OneColumnLayout>
          </div>
        )}
      </UserTranslate>
    );
  }
});

export default connect(state => ({
  user: state.user,
  customer: state.customer.data,
  userExists: state.user.state !== null,
  isLoading: !state._persist.rehydrated,
}))(function _UserPageWrapper({ userExists, isLoading, ...props }) {

  return (
    isLoading ?
      <LoadingIndicator /> : (
        userExists ?
          <UserPage {...props} /> :
          <Redirect to={routes.HOME.get()} />
      )
  )
});