import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { FormGroup } from 'reactstrap'

import ProductFormSectionPrice from './ProductFormSectionPrice'
import Field from '../fields/Field'

const ProductField = connect((state, { productFieldId }) => ({
  ...(find(state.productFields, x => x.id === productFieldId) || {}),
}))(class _ProductField extends React.Component {

  onChange = (fieldInput) => {
    const {
      onChange,
      input: {
        settingInputs = []
      } = {}
    } = this.props

    return onChange({
      ...fieldInput,
      settingInputs
    })
  }

  onSetSetting = (settingId, settingInput) => {
    const setting = find(this.props.settings, (x) => x.id === settingId)
    const { input } = this.props

    const fullInput = {
      ...input,
      settingInputs: [
        ...((input.settingInputs || []).filter(x => x.settingId !== setting.id)),
        {
          settingId: setting.id,
          ...settingInput
        }
      ]
    }

    this.props.onChange(fullInput)
  }

  onResetSetting = (settingId) => {
    const setting = find(this.props.settings, (x) => x.id === settingId)
    const { input } = this.props

    const fullInput = {
      ...input,
      settingInputs: [
        ...((input.settingInputs || []).filter(x => x.settingId !== setting.id)),
      ]
    }

    if (isEmpty(fullInput)) {
      this.props.onReset()
    }
    else {
      this.props.onChange(fullInput)
    }
  }

  render() {
    const {
      id, type, label, name,
      inputPriceBundle,
      inputPriceBundleState,
      input = {},
      settings = [],
      onChange, onReset,
      ...props
    } = this.props

    const haveInput = !isEmpty(input)
    const haveInputPrice = inputPriceBundle !== undefined && inputPriceBundle.additionalPriceCase !== undefined
    const inputPrice = (haveInputPrice ? inputPriceBundle.additionalPriceCase.price : undefined)

    const haveSettings = settings.length > 0

    return (
      <FormGroup key={`product-field-${id}`}>
        <h4>
          {
            haveInput &&
            <ProductFormSectionPrice
              value={inputPrice}
              placeholder={inputPriceBundleState === 'loading' && '..' || ''}
              alwaysShowPlusMinus={true}
              valueState={inputPriceBundleState}
            />
          }
          <span>{label || name}</span>
        </h4>
        <div className="Product_Option">
          <Field
            type={type}
            input={input}
            onChange={this.onChange}
            onReset={onReset}
            label={label || name}
            {...props}
          />
          {
            haveSettings &&
            <div className="Product_Option_Settings">
              <div className="Product_Option_Settings_Inner">
                {
                  settings.map(setting => (
                    <Field
                      key={setting.id}
                      {...setting}
                      placeholder={setting.placeholder || setting.label || setting.name}
                      label={setting.label || setting.name}
                      onReset={() => this.onResetSetting(setting.id)}
                      onChange={(settingInput) => this.onSetSetting(setting.id, settingInput)}
                      input={find(input.settingInputs, x => x.settingId === setting.id) || {}}
                    />
                  ))
                }
              </div>
            </div>
          }
        </div>
      </FormGroup>
    )
  }

})

ProductField.defaultProps = {
  isRequired: false,
  isDefault: false,
  numOfVariants: 1,
}

ProductField.propTypes = {
  productFieldId: PropTypes.number.isRequired,
  fieldId: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  isDefault: PropTypes.bool,
  numOfVariants: PropTypes.number
}

export default ProductField