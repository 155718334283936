import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { Link } from 'react-router-dom';

import routes from '../../routes';
import { getProductCategorySlugFromParams } from '../../routes';

import {
  getProductCategoryBySlug,
  getProductCategoryFlatTree,
  getFeaturedProductCategoryChildren,
} from '../../selectors';

import {
  setUserActivity
} from '../../actions';

import { waitUntilSatisfyingState } from '../hoc';
import { scrollToTop } from '../hoc';

import { OneColumnLayout } from '../../components/layouts';
import PageHeader from '../../components/PageHeader';
import DocumentMeta from '../../components/documentMeta';

import BaseArchivePage from './BaseArchivePage';

import ProductArchive from '../ProductArchive';
import FeaturedProductCategory from '../../components/FeaturedProductCategory';

import { ProductCategoryBreadcrumb } from '../../components/ProductBreadcrumbs';
import { ActivityFilter } from '../../components/productFilters';

import ProductArchiveOrdering from '../../components/ProductArchiveOrdering';

import GridArea from 'components/Grid/GridArea';


const ChildCategoryList = translate('category')(({ categories, getRoute, t }) => (
  <div>
    <p>
      <strong>{t('childCategories.label')}: </strong>

      {categories.map(childProductCategory => (
        <Link
          className="d-inline-block mr-1"
          key={childProductCategory.id}
          to={getRoute(childProductCategory)}
        >
          {childProductCategory.name}
        </Link>
      ))}
    </p>
  </div>
));

class ProductCategoryPage extends BaseArchivePage {

  getUrlWithQuery(newQuery) {
    const { productCategoryTree } = this.props;
    return routes.PRODUCT_CATEGORY.get(productCategoryTree, newQuery);
  }

  composeProductFiltersFromParams() {
    const { productCategory, haveChildren } = this.props;

    let filters = {
      ...super.composeProductFiltersFromParams(),
      categorySlugs: [productCategory.slug],
      includeItemsInChildCategories: haveChildren,
    };

    return filters;
  }

  getFilterComponents() {
    const { priceRange } = this.getQueryParams();

    return [];

    // return [{
    //   Component: PriceRangeFilter, 
    //   componentProps: {
    //     key: 'pricerange',
    //     selectedPriceRange: priceRange,
    //     onChange: this.composeSetFilter("priceRange")
    //   }
    // }]
  }

  render() {

    const { productCategory, productCategoryTree, productCategoryFeaturedChildren, activities } = this.props;
    const productFilters = this.composeProductFiltersFromParams();

    const query = this.getQueryParams(this.props);
    const { activity: activitySlug, priceRange, ordering } = query;

    const filterComponents = this.getFilterComponents();

    const blockConditions = [
      'page:product-category',
      `product-category:${productCategory.id}`,
      `product-category:${productCategory.slug}`,
    ];

    return (
      <div>
        <DocumentMeta
          title={productCategory.altTitle || productCategory.name}
          description={productCategory.altDescription || productCategory.description || ''}
          url={routes.PRODUCT_CATEGORY.get(productCategoryTree, query)}
          imageUrl={productCategory.image ? productCategory.image.processedUrls.default : null}
        />
        <GridArea
          areaSlug={"page-hero"}
          conditions={[blockConditions]}
        />

        <OneColumnLayout>
          <div>
            <ProductCategoryBreadcrumb categoryTree={productCategoryTree.slice(0, -1)} />
            <PageHeader title={productCategory.name} description={productCategory.description || ""} />

            <div className="text-center text-md-left px-2 px-md-0">
              {
                productCategory.children.length > 0 &&
                <ChildCategoryList
                  categories={productCategory.children}
                  getRoute={(childProductCategory) => {
                    return routes.PRODUCT_CATEGORY.get([productCategory, childProductCategory], query)
                  }}
                />
              }
            </div>

            <div>
              {productCategoryFeaturedChildren.map(category => (
                <FeaturedProductCategory key={category.id} {...category} />
              ))}
            </div>

            <div>
              <hr />
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="px-2 px-sm-5 px-md-2">
                    {filterComponents.map(({ Component, componentProps }) => (
                      <Component {...componentProps} />
                    ))}
                  </div>
                  {
                    filterComponents.length > 0 &&
                    <hr className="d-md-none" />
                  }
                </div>
                <div className="col-md-4 offset-md-4 col-xs-12">
                  <div className="px-2 px-sm-5 px-md-2">
                    <ProductArchiveOrdering selectedOrdering={ordering} onChange={this.setOrdering} />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div>
              <ProductArchive
                filters={productFilters}
                ordering={ordering}
                blockConditions={blockConditions}
                listType={`product-category--${productCategoryTree.map(c => c.slug).join('/')}`}
              />
            </div>
          </div>
        </OneColumnLayout>
      </div>
    );
  }
};

function mapStateToCategoryPageProps(state, ownProps) {

  const { productCategory: { id: productCategoryId }, productCategory } = ownProps;
  const productCategoryTree = getProductCategoryFlatTree(state, productCategoryId);
  const productCategoryFeaturedChildren = getFeaturedProductCategoryChildren(state, productCategoryId);

  const haveChildren = productCategory.children.length > 0;

  return {
    productCategoryTree,
    productCategoryFeaturedChildren,
    haveChildren
  }
}

const ConnectedProductCategoryPage = connect(mapStateToCategoryPageProps)(ProductCategoryPage);


class ProductCategoryThatUsesActivitiesPage extends ProductCategoryPage {
  constructor(props) {
    super(props)
    this.setActivityFilter = this.setActivityFilter.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { userActivitySlug } = this.props;
    const { activity: queryActivitySlug = null } = this.getQueryParams();

    if (userActivitySlug !== queryActivitySlug) {
      this.changeUrlQuery({
        activity: userActivitySlug
      }, "replace");
    }
  }

  setActivityFilter(activitySlug) {
    const { setUserActivity } = this.props;
    setUserActivity(activitySlug || null);
  }

  composeProductFiltersFromParams() {
    const { activity } = this.getQueryParams(this.props);

    let filters = {
      ...super.composeProductFiltersFromParams(),
    };

    if (activity !== undefined) {
      filters.activitySlugs = [activity];
    }
    else {
      filters.excludeItemsWithActivities = true;
    }

    return filters;
  }

  getFilterComponents() {

    const { activities } = this.props;
    const { activity: activitySlug } = this.getQueryParams(this.props);

    const parentFilterComponents = super.getFilterComponents();

    return [
      {
        Component: ActivityFilter,
        componentProps: {
          key: 'activity',
          selectedActivitySlug: activitySlug,
          onChange: this.setActivityFilter,
          activities,
        }
      },
      ...parentFilterComponents,
    ]
  }
}

function mapStateToCategoryThatUsesActivitiesPageProps(state, ownProps) {

  const { activities, userSettings: { activitySlug: userActivitySlug } } = state;

  return {
    ...mapStateToCategoryPageProps(state, ownProps),
    activities,
    userActivitySlug
  }
}

const ConnectedProductCategoryThatUsesActivitiesPagePage = connect(mapStateToCategoryThatUsesActivitiesPageProps, {
  setUserActivity
})(ProductCategoryThatUsesActivitiesPage);


class ProductCategoryPageWrapper extends React.Component {
  render() {
    const {
      isReady,
      isSignedIn,
      match: { params },
      location,
      history,
      productCategory
    } = this.props;

    const categoryUsesActivities = productCategory.usesActivities !== undefined ? productCategory.usesActivities : true;

    const props = {
      match: { params },
      location,
      history,
      productCategory,
      isSignedIn
    }

    return (
      <div key={productCategory.id}>
        {
          categoryUsesActivities ?
            <ConnectedProductCategoryThatUsesActivitiesPagePage {...props} /> :
            <ConnectedProductCategoryPage {...props} />
        }
      </div>
    )
  }
}

const mapWrapperStateToProps = (state, ownProps) => {
  const { productCategories } = state;
  const { match: { params } } = ownProps;

  const isReady = productCategories.length > 0;

  const productCategorySlug = getProductCategorySlugFromParams(params);
  const productCategory = getProductCategoryBySlug(state, productCategorySlug);

  return {
    productCategory,
    isReady,
    isSignedIn: state.auth.isSignedIn,
    isSignedInAndStaff: state.auth.isSignedIn && state.user && state.user.type === 'staff',
  }
}

export default waitUntilSatisfyingState(
  state => (state.productCategories.length > 0),
  mapWrapperStateToProps,
  {},
)(scrollToTop()(ProductCategoryPageWrapper));